import React from 'react';
import WebComponentsConfig from '../components/WebComponentsConfig';

import png502 from '../images/502.png';
import background from '../images/background.png';

import { 
    OmErrorPage,
    OmWizardHeader,
    OmErrorIllustrationControl
} from '@om/component-library-react';

const ErrorPage = () => (
    <WebComponentsConfig>
        <OmErrorPage>
            <OmWizardHeader slot="header" />
            <OmErrorIllustrationControl
                slot="error-illustration-control"
                buttonUrl="/"
                buttonText="GO TO HOME"
                backgroundImage={background}
                errorImage={png502}
            >
                <h4 slot="main-text">
                    Oops! This is awkward.
                </h4>
                <span slot="sub-text">We seem to be having issues with our servers.</span>
            </OmErrorIllustrationControl>
        </OmErrorPage>
    </WebComponentsConfig>
);

export default ErrorPage;
